import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom'
import getLinks from '../../services/getLinks'
import Logo from '../../images/logo.png'
import downloadIos from '../../images/download-ios.png'
import downloadAndroid from '../../images/download-android.png'

import './index.scss'

const AppPage = () => {
  const [storeLinks, setStoreLinks] = useState({
    ios: "",
    android: ""
  })
  const [isLoading, setLoading] = useState(true)

  const { pathname } = useLocation()

  const getStoreLinks = async (urlIdentifier) => {
    const res = await getLinks(urlIdentifier)

    if (/android/i.test(navigator.userAgent)) {
      handleNavigate(res.android)
    }

    if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
      handleNavigate(res.ios)
    }

    setStoreLinks(res)
    setLoading(false)
  }

  useEffect(() => {
    if (pathname === "/.well-known/apple-app-site-association") {
      return window.location.href = "https://celapp-share.s3.eu-central-1.amazonaws.com/ios-file/apple-app-site-association"
    } else {
      const pathArray = pathname.split('/')

      if (pathArray[1] !== "") {
        getStoreLinks(pathArray[1])
      }
    }
    
    setLoading(false)
  }, [pathname])

  const handleNavigate = (url) => {
    window.location.href = url
  }

  return isLoading ? (<div>Please wait ...</div>) : (
    <div className="app-container">
      <div className="app-content" >
        <img src={Logo} alt="logo" />
        <p className="hint">The app has not been installed yet. Please install the application to see the content.</p>
        <div className="download-btns">
          <div className="download-ios">
            <img src={downloadIos} alt="" onClick={() => !!storeLinks.ios && handleNavigate(storeLinks.ios)} />
          </div>
          <div className="download-android">
            <img src={downloadAndroid} alt="" onClick={() => !!storeLinks.android && handleNavigate(storeLinks.android)} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppPage;