import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom'

import AppPage from './AppPage'

import './App.scss'

const App = () => {
  return (
    <div className="main-container">
     <Switch >
       <Route  path="/" component={AppPage}/>

       <Redirect to="/"/>
     </Switch>
    </div>
  );
};


export default App;